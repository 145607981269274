@import "../../../mixins";

.about-section {
  & > .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    column-gap: var(--grid-column-gap);
    row-gap: var(--grid-row-gap);

    @include mediaTablet {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: rem(40);
    }

    @include mediaMobile {
      gap: rem(25);
    }
  }

  & .section {
    &__head {
      grid-column: 1/2;

      padding-right: rem(110);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: rem(90);

      @include mediaBigDesktop {
        padding-right: big(110);
        gap: big(90);
      }

      @include mediaDesktop {
        padding-right: rem(100);
      }

      @include mediaLaptop {
        padding-right: rem(60);
        gap: rem(60);
      }

      @include mediaTablet {
        padding-right: 0;
        gap: rem(35);
      }

      @include mediaMobile {
        gap: rem(25);
      }
    }

    &__title {
      flex-basis: unset;
    }

    &__name {
      font: var(--font-h3);
    }
  }

  &__image {
    position: relative;

    width: 100%;
    height: auto;
    aspect-ratio: 848/436;

    border-radius: var(--radius-common);
    overflow: hidden;

    user-select: none;
    pointer-events: none;

    @supports not (aspect-ratio: 1/1) {
      height: rem(436);

      @include mediaBigDesktop {
        height: big(436);
      }
    }

    @include mediaDesktop {
      aspect-ratio: 646/332;

      @supports not (aspect-ratio: 1/1) {
        height: rem(332);
      }
    }

    @include mediaLaptop {
      aspect-ratio: 495/280;

      border-radius: var(--radius-secondary);

      @supports not (aspect-ratio: 1/1) {
        height: rem(280);
      }
    }

    @include mediaTablet {
      aspect-ratio: 688/270;

      @supports not (aspect-ratio: 1/1) {
        height: rem(270);
      }
    }

    @include mediaMobile {
      aspect-ratio: 290/150;

      @supports not (aspect-ratio: 1/1) {
        height: rem(150);
      }
    }

    & img,
    & picture {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      background-color: var(--bg-dark);
      object-fit: cover;
      object-position: center;
    }
  }

  &__list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: var(--grid-column-gap);
    row-gap: rem(30);

    @include mediaBigDesktop {
      row-gap: big(30);
    }

    @include mediaDesktop {
      row-gap: rem(15);
    }

    @include mediaTablet {
      row-gap: rem(25);
    }

    @include mediaMobile {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      row-gap: rem(15);
    }

    & > .about-advantages {
      &:last-child:not(:nth-child(2n)) {
        grid-column: 1/3;
      }
    }
  }
}
